import React from 'react'
import CopytCard from 'components/CopytCard'
import {Accordion, AccordionDetails, AccordionSummary, Box} from '@mui/material'
import {ArrowDropDown as ArrowDropDownIcon} from '@mui/icons-material'
import Select from 'react-select'
import {category_options, color_options, tag_options} from 'assets/data'
import {options, accessories_options, apparel_options} from 'assets/data/options/sub_category_options'
import {FieldGroup, CopytTooltip, CopytLabel} from 'views_v2/lib/snippets'

export const Overview = ({
  errorMessage,
  formFields,
  handleInputChange,
  hasError,
  hasSkuIssue,
  isEnterprise,
  productTemplate,
  setFormFields,
  setProductTemplate,
  template = [],
  updateDescriptionTemplate,
  readOnly = false,
  isListing = false,
}) => {
  let subCategory = options
  if (formFields?.category?.toLowerCase() === 'apparel') {
    subCategory = apparel_options
  } else if (formFields?.category?.toLowerCase() === 'accessories') {
    subCategory = accessories_options
  }

  return (
    <CopytCard className="consignor-information">
      <CopytCard.Header>
        <CopytCard.Text tag="div">Overview</CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body>
        <FieldGroup
          {...{readOnly}}
          sLabel="Brand *"
          onChange={handleInputChange}
          maxLength={30}
          required
          name="brand"
          value={formFields?.brand || ''}
          tabIndex="9"
          hasError={hasError && !formFields.brand}
          errorMessage={`Brand ${errorMessage}`}
        />
        <FieldGroup
          {...{readOnly}}
          sLabel="Name *"
          required
          maxLength={70}
          name="title"
          onChange={handleInputChange}
          value={formFields?.title || ''}
          tabIndex="10"
          hasError={hasError && !formFields.title}
          errorMessage={`Item Name ${errorMessage}`}
        />
        <FieldGroup
          {...{readOnly}}
          sLabel={`SKU/Style ID ${
            formFields?.category === 'Apparel' || formFields?.category === 'Accessories'
              ? ''
              : `(include '-')`
          }`}
          maxLength={20}
          onChange={(e) =>
            setFormFields({
              ...formFields,
              sku: e.target.value,
            })
          }
          value={formFields?.sku || ''}
          tabIndex="11"
          hasError={hasSkuIssue || hasError === 'is formatted incorrectly'}
          errorMessage={`SKU ${errorMessage}`}
        />
        <Box display={isListing && 'none'}>
          <CopytLabel className="half-width">Use Product Template?</CopytLabel>
          <Select
            className="react-select info"
            classNamePrefix="react-select"
            isDisabled={template?.length === 0}
            onChange={(e) => {
              setProductTemplate(e)
              let category = `Men's Shoes`
              let condition = 'Brand New'
              let description = 'SKU: [_]\n'
              let boxWeight = '4 lb'

              if (template?.length > 0 && e?.value) {
                const tmp = template[0]
                category = tmp?.category
                condition = tmp?.condition
                description = updateDescriptionTemplate('condition', tmp?.description)
                boxWeight = `${tmp?.shippingWieght} lb`
              }

              setFormFields({
                ...formFields,
                category,
                condition,
                description,
                boxWeight,
              })
            }}
            options={[
              {value: false, label: 'No'},
              {value: true, label: `Yes - ${template[0]?.name}`},
            ]}
            value={productTemplate}
            tabIndex="12"
          />
        </Box>
        <Box>
          {isListing ? (
            <FieldGroup sLabel="Category" readOnly name="category" value={formFields?.category || ''} />
          ) : (
            <>
              <CopytLabel marginTop="10px">Category</CopytLabel>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                placeholder="Choose Category"
                closeMenuOnSelect
                isDisabled={isListing || productTemplate?.value}
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    category: e.value,
                    subcategory: '',
                  })
                }
                options={category_options}
                value={
                  (formFields.category && {
                    value: formFields.category,
                    label: formFields.category,
                  }) ||
                  ''
                }
                tabIndex="12"
              />
            </>
          )}
        </Box>
        <Box display={!isListing && 'none'}>
          <FieldGroup
            sLabel="Sub Category"
            readOnly
            name="subCategory"
            value={formFields?.subcategory || ''}
          />
        </Box>
        <Box display={!isListing && 'none'}>
          <FieldGroup sLabel="Color" readOnly name="color" value={formFields?.color || ''} />
        </Box>

        <CopytTooltip
          title="These optional fields are currently only used on eBay listings."
          placement="right"
        >
          <Accordion sx={{marginTop: '10px', display: isListing && 'none'}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{minHeight: '40px !important', height: '40px'}}
            >
              <CopytLabel>Additional Fields</CopytLabel>
            </AccordionSummary>
            <AccordionDetails sx={{borderTop: '1px solid #eee'}}>
              <CopytLabel marginTop={1}>Subcategory</CopytLabel>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                placeholder="Choose Subcategory"
                closeMenuOnSelect
                isClearable
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    subcategory: e?.value || '',
                  })
                }}
                options={subCategory}
                value={
                  (formFields?.subcategory && {
                    value: formFields?.subcategory,
                    label: formFields?.subcategory,
                  }) ||
                  ''
                }
                tabIndex="13"
              />
              <CopytLabel marginTop={1}>Color</CopytLabel>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                placeholder="Choose Color"
                closeMenuOnSelect
                isClearable
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    color: e?.value || '',
                  })
                }
                options={color_options}
                value={
                  (formFields?.color && {
                    value: formFields.color,
                    label: formFields.color,
                  }) ||
                  ''
                }
                tabIndex="14"
              />
              <CopytLabel marginTop={1}>Tag status</CopytLabel>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                placeholder="Choose Tag Status"
                closeMenuOnSelect
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    tagStatus: e.value,
                  })
                }
                options={tag_options}
                value={
                  (formFields?.tagStatus && {
                    value: formFields.tagStatus,
                    label: formFields.tagStatus,
                  }) || {value: 'Intact', label: 'Intact'}
                }
                tabIndex="17"
              />
            </AccordionDetails>
          </Accordion>
        </CopytTooltip>
      </CopytCard.Body>
    </CopytCard>
  )
}

export default Overview
