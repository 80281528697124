import React from 'react'
import _ from 'lodash'
import Select from 'react-select'
import {Grid} from '@mui/material'
import {box_options, category_options, condition_options} from 'assets/data'
import {CopytLabel, SizesDropdown, FieldGroup} from 'views_v2/lib/snippets'
import {onKeyPress} from 'views_v2/lib/common'
import {formatTo} from 'util/date_utils'
import {SizesContainer} from '../../snippets'
import {onlySpaces} from 'views_v2/lib/common'

const SimplifiedInventoryDetails = (props) => {
  const {
    editMode,
    errorMessage,
    hasError,
    handleArrow,
    handleInputChange,
    handleValueChange,
    handleSizeChange,
    hasSkuIssue = false,
    isEnterprise,
    isConsigned,
    isDesiredReturnReadOnly = false,
    formFields,
    productTemplate,
    onDeleteItem,
    onChange = () => null,
    setFormFields,
    readOnly = false,
  } = props

  const isListPrice = isEnterprise && isConsigned

  return (
    <React.Fragment>
      <FieldGroup
        {...{readOnly}}
        sLabel="Brand *"
        onChange={handleInputChange}
        maxLength={30}
        required
        name="brand"
        placeholder="Enter brand"
        value={formFields?.brand || ''}
        tabIndex="9"
        hasError={hasError && !formFields.brand}
        errorMessage={`Brand ${errorMessage}`}
      />
      <FieldGroup
        {...{readOnly}}
        sLabel="Name *"
        required
        maxLength={70}
        name="title"
        onChange={handleInputChange}
        placeholder="Enter name"
        value={formFields?.title || ''}
        tabIndex="10"
        hasError={hasError && !formFields.title}
        errorMessage={`Item Name ${errorMessage}`}
      />
      <FieldGroup
        {...{readOnly}}
        sLabel={`SKU/Style ID ${
          formFields?.category === 'Apparel' || formFields?.category === 'Accessories'
            ? ''
            : `(include '-')`
        }`}
        maxLength={20}
        name="sku"
        onChange={handleInputChange}
        placeholder="Enter SKU"
        value={formFields.sku || ''}
        tabIndex="11"
      />

      <Grid container gap={0.5} display="flex" flexDirection="row" className="grid-invntry">
        <Grid item xs={12} lg={5.9}>
          <CopytLabel>Category</CopytLabel>
          <Select
            className="react-select info"
            classNamePrefix="react-select"
            placeholder="Choose Category"
            closeMenuOnSelect
            isDisabled={productTemplate?.value}
            onChange={(e) =>
              setFormFields({
                ...formFields,
                category: e.value,
                subcategory: '',
              })
            }
            options={category_options}
            value={
              (formFields.category && {
                value: formFields.category,
                label: formFields.category,
              }) ||
              ''
            }
            tabIndex="12"
          />
        </Grid>
        <Grid item xs={12} lg={5.9}>
          <CopytLabel
            sx={{
              color:
                hasError && (!formFields.size || formFields.size?.length === 0) && '#d3103f !important',
            }}
          >
            Sizes *
          </CopytLabel>
          {readOnly ? (
            <FieldGroup value={formFields.size} readOnly />
          ) : (
            <SizesDropdown
              hasError={hasError && (!formFields.size || formFields.size?.length === 0)}
              {...{handleSizeChange, formFields, editMode, readOnly}}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <SizesContainer
            arr={formFields.size}
            {...{errorMessage, handleArrow, handleValueChange, onDeleteItem}}
          />
        </Grid>
        <Grid item xs={12} md={5.9}>
          <CopytLabel>Item Condition</CopytLabel>
          {readOnly ? (
            <FieldGroup value={formFields.condition} readOnly />
          ) : (
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="Choose Condition"
              closeMenuOnSelect
              isDisabled={productTemplate?.value || readOnly}
              onChange={(e) =>
                setFormFields({
                  ...formFields,
                  condition: e.value,
                })
              }
              options={condition_options}
              value={
                (formFields.condition && {
                  value: formFields.condition,
                  label: formFields.condition,
                }) || {value: 'Brand New', label: 'Brand New'}
              }
              tabIndex="18"
            />
          )}
        </Grid>
        <Grid item xs={12} md={5.9}>
          <CopytLabel>Box Condition</CopytLabel>
          {readOnly ? (
            <FieldGroup value={formFields.boxCondition} readOnly />
          ) : (
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="Choose Box Condition"
              closeMenuOnSelect
              onChange={(e) =>
                setFormFields({
                  ...formFields,
                  boxCondition: e?.value,
                })
              }
              options={box_options}
              value={
                (formFields.boxCondition && {
                  value: formFields.boxCondition,
                  label: formFields.boxCondition,
                }) || {
                  value: 'Good Condition',
                  label: 'Good Condition',
                }
              }
              tabIndex="16"
            />
          )}
        </Grid>
        <Grid item xs={12} md={5.9}>
          <FieldGroup
            {...{readOnly}}
            sLabel="Acquired Date"
            type={readOnly ? 'text' : 'date'}
            value={
              isListPrice
                ? `${formatTo(formFields?.updateAt, 'YYYY-MM-DD')}` || ''
                : formFields?.acquiredDate || ''
            }
            onChange={(e) => {
              onChange({acquiredDate: e.target.value})
            }}
          />
        </Grid>
        <Grid item xs={12} md={5.9}>
          <FieldGroup
            readOnly={readOnly && isDesiredReturnReadOnly}
            sLabel={isListPrice ? 'List Price' : 'Buy Price'}
            value={isListPrice ? formFields?.desiredReturn : formFields?.price || ''}
            onChange={(e) => {
              const key = isListPrice ? 'desiredReturn' : 'price'
              onChange({[key]: e.target.value})
            }}
            required
            placeholder={`Enter ${isListPrice ? 'list' : 'buy'} price`}
            id="numeric"
          />
        </Grid>
      </Grid>
      {!isListPrice && (
        <FieldGroup
          readOnly={readOnly && isDesiredReturnReadOnly}
          sLabel="List Price *"
          value={formFields?.desiredReturn || ''}
          onChange={(e) => {
            onChange({desiredReturn: e.target.value})
          }}
          data-testid="desiredReturn"
          required
          placeholder="Enter list price"
          hasError={hasError && (!formFields.desiredReturn || !onlySpaces(formFields?.desiredReturn))}
          errorMessage={`List Price ${errorMessage}`}
          id="numeric"
        />
      )}
    </React.Fragment>
  )
}

export default SimplifiedInventoryDetails
