import React, {useCallback, useState, useEffect, useContext} from 'react'
import clx from 'classnames'
import {DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF} from '@mui/x-data-grid'
import {Box, Checkbox, Chip, Stack, Tooltip} from '@mui/material'
import {debounce, isEmpty, orderBy} from 'lodash'
import axios from 'axios'
import {captureException} from '@sentry/browser'
import {HourglassBottom} from '@mui/icons-material'
import {useAuth0} from '@auth0/auth0-react'

import DetailView from 'components/DetailView'
import {SoldTableFilterMenu} from 'assets/data/options/inventory_filter_menu'
import {ModalType, TableFooterType} from 'enums'
import {paths} from 'constant/api'
import {useUserPrivate} from 'service/hook/useUserPrivate'
import {useGlobalStore} from 'provider/global_store/hook'
import {generateExportData} from 'views_v2/modules/Inventory/hooks/util'
import {CopytTooltip} from 'views_v2/lib/snippets'
import {ConsignorProfile} from 'views_v2/modules/Consignments/component/consignment_application/ConsignorProfile'
import {getCurrency} from 'util/model/setting'
import {getProfit} from 'util/model/listing'
import {getSoldPlatformName, getSoldPlatformDate, isConsigned} from 'util/model/product'
import {capitalizeText, _getValue} from 'util/string_utils'
import {getActualReturn} from 'util/model/sold'
import DateUtils from 'util/date_utils'
import {ReactComponent as ConsignedBannerSvg} from '../../../../../../assets/img/consigned-banner_v2.svg'
import {CustomFooter, EmptyInventory, InventoryToolbar} from '../../../snippets'
import {SearchContext, TabPanel} from '../../../index'

const SoldTable = (p) => {
  const {
    date,
    disableBtn,
    handleChange,
    items,
    refetch,
    markAsProcessed,
    routeChange,
    filter,
    isFetching,
    isCardList = false,
    queries,
    user,
    setModalType,
    currentPage,
    setCurrentPage,
    onNavigateUrl,
    isLoading,
    metricsQuery,
  } = p

  const {isEnterprise} = useGlobalStore()
  const {userPrivate} = useUserPrivate()
  const [minConsignFee, setMinConsignFee] = useState()
  const currency = getCurrency()
  const isMobile = window.innerWidth <= 850
  const search = useContext(SearchContext)
  const onSearch = useCallback(
    debounce((searchKey) => {
      search.setSoldSearchText(searchKey), 1000
    }),
    [],
  )

  const [processingMultiple, setProcessingMultiple] = useState(false)

  const {getAccessTokenSilently} = useAuth0()

  const [selectedRowIds, setSelectedRowIds] = useState([])

  useEffect(() => {
    setMinConsignFee(userPrivate?.minimumConsignmentFee)
  }, [userPrivate])

  const columns = [
    {
      field: 'assets',
      headerName: '',
      flex: isEnterprise ? 1 : 0.7,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const {assets, consign} = params.row || {}
        const hasConsignBanner = isConsigned(params.row)
        const hasNoImage = assets?.length === 0
        const image = hasNoImage
          ? null
          : assets.reduce((a, b) => {
              return Math.abs(b.order - 1) < Math.abs(a.order - 1) ? b : a
            })

        return (
          <div className={clx(hasNoImage && hasConsignBanner && 'no-image', 'img-overlay-wrap')}>
            {hasConsignBanner && <ConsignedBannerSvg />}
            {!hasNoImage && <img src={image?.asset?.url} />}
          </div>
        )
      },
    },
    {
      field: 'title',
      headerName: 'Name',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'sku',
      headerName: 'SKU/Style ID',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: isMobile,
    },
    {
      field: 'size',
      headerName: 'Size',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Buy Price',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        return isConsigned(params.row) ? '' : `${currency.format(params?.value)}`
      },
    },
    {
      field: 'actualReturn',
      headerName: 'Actual Return',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => `${currency.format(getActualReturn(params?.row, user))}`,
    },
    {
      field: 'profit',
      headerName: 'Profit',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        const {listings} = params.row
        return currency.format(getProfit(listings, isConsigned(params.row), isEnterprise))
      },
    },
    {
      field: 'soldPlatform',
      headerName: 'Sold Platform',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: isMobile,
      renderCell: (params) => getSoldPlatformName(params?.row),
    },
    {
      field: 'soldDate',
      headerName: 'Sold Date',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: isMobile,
      renderCell: (params) => getSoldPlatformDate(params?.row),
    },
    {
      field: 'daysListed',
      headerName: 'Duration',
      headerAlign: 'center',
      align: 'center',
      flex: 0.6,
      renderCell: (params) => {
        const item = params?.row
        const {today, raw, days} = DateUtils.calculateDateDifference(
          item.listings,
          item?.createdAt,
          'solds',
        )

        return (
          <CopytTooltip
            title={
              <Stack direction="column">
                <Stack direction="row">Acquired Date: {DateUtils.formatToLocalDate(today)}</Stack>
                <Stack direction="row">Sold Date: {DateUtils.formatToLocalDate(raw)}</Stack>
              </Stack>
            }
          >
            <Box component="span">{days}</Box>
          </CopytTooltip>
        )
      },
    },
    {
      field: 'internalSku',
      headerName: 'Internal SKU',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: !isEnterprise || isMobile,
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 1,
      hide: !isEnterprise || isMobile,
    },
    {
      field: 'subLocation',
      headerName: 'Sub Location',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: !isEnterprise || isMobile,
    },
    {
      headerName: 'Shipping Status',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: isMobile,
      renderCell: (params) => {
        const {shipments} = params?.row || {}
        if (!shipments?.length) return

        shipments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        const [{shipment}] = shipments

        let label = shipment?.status

        switch (shipment?.type) {
          case 'ORDER_FULFILLMENT': {
            switch (shipment?.status) {
              case 'TRANSIT': {
                label = 'RECEIVED'
                break
              }
            }
            break
          }
        }

        return (
          <CopytTooltip title={label}>
            <Chip label={capitalizeText(label)} size="small" />
          </CopytTooltip>
        )
      },
    },
    {
      field: 'owner',
      headerName: 'Owner',
      headerAlign: 'center',
      flex: 2,
      hide: isMobile,
      renderCell: (params) => {
        const hasConsignBanner = isConsigned(params.row)
        const owner = params?.row?.owner
        const title = owner?.businessName || owner?.email

        return (
          <Tooltip {...{title}}>
            <Box component="span">
              {hasConsignBanner ? (
                <ConsignorProfile
                  consignorEmail={params?.row?.consign?.consignor?.email}
                  consignorId={params?.row?.consign?.consignor?.id}
                />
              ) : (
                <span>{title}</span>
              )}
            </Box>
          </Tooltip>
        )
      },
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 0.2,
    },
  ]

  const modifiedCheckboxColumn = {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderCell: (params) => {
      if (params?.row.onQueue) {
        return (
          <CopytTooltip title="">
            <HourglassBottom />
          </CopytTooltip>
        )
      } else {
        return GRID_CHECKBOX_SELECTION_COL_DEF.renderCell ? (
          GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params)
        ) : (
          <Checkbox />
        )
      }
    },
    flex: 0.2,
  }

  const newColumns = columns.map((col) =>
    col.type === 'checkboxSelection' ? modifiedCheckboxColumn : col,
  )

  const SearchToolBar = () => {
    if (!isCardList) {
      return (
        <InventoryToolbar
          {...{disableBtn, queries}}
          filterMenu={SoldTableFilterMenu}
          search={{
            disabled: isFetching,
            value: search?.soldSearchText,
            onSearch: onSearch,
          }}
          action={{
            onNavigateUrl: onNavigateUrl,
            onSaveChanges: p?.onSaveChanges,
            onCustomDate: () => setModalType(ModalType.DATE_PICKER),
            onProcessMultiple: async () => {
              setProcessingMultiple(true)
              const token = await getAccessTokenSilently()
              const processMultiplePromise = selectedRowIds.map(async (id) => {
                try {
                  await axios.post(
                    `${paths.listing}/status`,
                    {listingId: id, status: 'processed'},
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    },
                  )
                } catch (e) {
                  captureException(e)
                }
              })

              await Promise.all(processMultiplePromise)
              refetch()
              setSelectedRowIds([])
              setProcessingMultiple(false)
            },
          }}
          allItems={generateExportData(p?.allData, TabPanel.SOLD)}
          selectedTab="Sold"
          hasCustomDate
          selectedRowIds={selectedRowIds}
        />
      )
    }
    return null
  }

  const CustomNoRowsOverlay = () => {
    return (
      <EmptyInventory
        {...{disableBtn, handleChange, markAsProcessed, routeChange}}
        type="listing"
        tabSelection="sold"
      />
    )
  }

  const rows = isCardList ? p?.cardItem : items?.data

  const sortedRows = queries?.sortBy
    ? rows
    : orderBy(rows, [(item) => item.listings[0].solds[0].createdAt], ['desc'])

  return (
    <DetailView.Panel style={{height: isCardList ? '500px' : '100%'}}>
      <Box className="inventory-container">
        <DataGrid
          rows={sortedRows || []}
          columns={newColumns}
          disableColumnSelector
          disableColumnMenu
          disableDensitySelector
          disableVirtualization
          checkboxSelection
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids)
            const selectedRowData = sortedRows?.filter((row) => selectedIDs.has(row?.id))
            setSelectedRowIds(selectedRowData.map((row) => row?.listings[0].id))
          }}
          loading={isLoading || isFetching || processingMultiple}
          onCellClick={(param) => {
            if (param?.field === '__check__') {
              return
            } else {
              const {listings} = param?.row
              if (listings?.length > 0) {
                routeChange(`/admin/listings/viewSold/${listings[0]?.id}`)
              }
            }
          }}
          components={{
            Toolbar: SearchToolBar,
            NoRowsOverlay: CustomNoRowsOverlay,
            Footer: CustomFooter,
          }}
          componentsProps={{
            isCardList,
            footer: isCardList
              ? undefined
              : {
                  total: _getValue(metricsQuery?.data?.totalActualReturn),
                  label: TableFooterType.ACTUAL_RETURN,
                  otherFooters: [
                    {
                      value: _getValue(metricsQuery?.data?.totalCostOfGoods),
                      label: TableFooterType.TOTAL_COST_GOODS,
                    },
                    {
                      value: metricsQuery?.data?.totalProfit,
                      label: TableFooterType.TOTAL_PROFIT,
                      percentage: _getValue(metricsQuery?.data?.profitMetric)?.toFixed(2),
                    },
                    {
                      value: _getValue(metricsQuery?.data?.totalSoldPrice),
                      label: TableFooterType.TOTAL_GROSS_SALES,
                    },
                  ],
                },
          }}
          getRowId={(row) => row.id}
          filterMode="server" //use this if the filtering/searching of the item will be done on the server or via API
          filterModel={{
            items: [],
            quickFilterValues: [search?.soldSearchText],
          }}
          onFilterModelChange={(s) => {
            onSearch(s?.quickFilterValues[0])
          }}
          rowCount={isCardList ? rows?.length : items?.total}
          onPageChange={(newPage) => {
            if (!isLoading && !isFetching && !isCardList) {
              setCurrentPage(newPage)
            }
          }}
          page={currentPage}
          pageSize={100}
          pagination
          paginationMode="server"
          disablePagination={isLoading || isFetching || processingMultiple}
          rowsPerPageOptions={[100]}
          onSortModelChange={(newSort) => (isEmpty(rows) ? null : p?.onSaveChanges({sort: newSort[0]}))}
          sx={{
            '& .MuiDataGrid-main': {
              bgcolor: 'white',
            },
            '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-footer': {
              bgcolor: '#faebf5',
            },
            '& .MuiTablePagination-actions': {
              button: {
                opacity: isLoading || isFetching || processingMultiple ? 0.3 : 1,
                cursor: isLoading || isFetching || processingMultiple ? 'no-drop' : 'pointer',
                pointerEvents: isLoading || isFetching || processingMultiple ? 'none' : '',
              },
              '& .Mui-disabled': {
                opacity: 0.3,
              },
            },
          }}
        />
      </Box>
    </DetailView.Panel>
  )
}

export default React.memo(SoldTable)
