import {useCallback, useState, useEffect} from 'react'
import {isEmpty} from 'lodash'

import {useGlobalStore} from 'provider/global_store/hook'
import {usePlatformAccepted} from 'service/hook/use_platform'

export default function usePlatformSelectionHook() {
  const {isEnterprise} = useGlobalStore()
  const {data: platforms} = usePlatformAccepted()

  const [selected, setSelected] = useState([])

  useEffect(() => {
    if (platforms && !isEmpty(platforms)) {
      setSelected(platforms)
    }
  }, [platforms])

  const onChange = useCallback(
    (e) => {
      const id = e.target.value
      const checked = e.target.checked

      if (checked) {
        setSelected((v) => [...v.filter((i) => i.id !== id), platforms.find((i) => i.id === id)])
      } else {
        setSelected((v) => v.filter((i) => i.id !== id))
      }
    },
    [setSelected, platforms],
  )

  return {platforms, onChange, selected, setSelected, isEnterprise}
}
