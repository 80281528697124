import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Box, Checkbox, Typography, Stack, FormControl, LinearProgress} from '@mui/material'
import Select from 'react-select'
import {chain, get, isEmpty} from 'lodash'

import {ModalType} from 'enums'
import {_replaceWildCards} from 'util/string_utils'
import {CopytLabel, CopytStyledButton, LoadingBar} from 'views_v2/lib/snippets'
import {useUnlistedItemInventory} from 'service/hook/useItem'
import {InventoryQueryType} from 'constant/query_type'
import SearchBar from 'material-ui-search-bar'
import {StyledSearchBar} from '..'
import {CopytTooltip} from 'views_v2/lib/snippets'

const MdlListedUnlistedItems = (props) => {
  const {
    shipmentType,
    setModalType,
    onSaveChanges,
    isCreatingShipment,
    createAnotherShipmentId,
    isCreatingAnotherShipment,
    duplicateCount,
    setDuplicateCount,
    selectedShipmentEventId,
    setSelectedShipmentEventId,
  } = props
  const [boxItems, setBoxItems] = useState([])
  const [queries] = useState({
    skip: 0,
    take: 500,
    page: 0,
  })
  const [searchText, setSearchText] = useState('')
  const {items: unlistedItems, isFetching: isFetchingUnlistedItems} = useUnlistedItemInventory(
    InventoryQueryType.UNLISTED,
    queries,
    searchText,
  )
  const {items: listedItems, isFetching: isFetchingListedItems} = useUnlistedItemInventory(
    InventoryQueryType.LISTED,
    queries,
    searchText,
  )

  const [tab, setTab] = useState('unlisted')

  const [productsInActiveTracking, setProductsInActiveTracking] = useState([])

  useEffect(() => {
    const activeTrackingStatuses = ['DRAFT', 'PRE_TRANSIT', 'TRANSIT']

    if (unlistedItems?.data || listedItems?.data) {
      const ids = [...unlistedItems?.data, ...listedItems.data].reduce((acc, item) => {
        if (item?.shipments?.length) {
          item.shipments.forEach(({shipment}) => {
            if (activeTrackingStatuses?.includes(shipment?.status)) {
              acc.push(item.id)
            }
          })
        }
        return acc
      }, [])
      setProductsInActiveTracking(ids)
    }
  }, [unlistedItems])

  const handleCheckboxChange = (item) => {
    setBoxItems((prevItems) => {
      // Check if the item is already in the array
      const itemIndex = prevItems.findIndex((i) => i.id === item.id)
      if (itemIndex > -1) {
        // Remove the item if it exists
        return prevItems.filter((i) => i.id !== item.id)
      } else {
        // Add the item if it doesn't exist
        return [...prevItems, item]
      }
    })
  }

  const handleCheckboxOnChange = () => {
    if (isEmpty(boxItems)) {
      const filtered = unlistedItems?.data.filter((item) => !productsInActiveTracking.includes(item.id))
      setBoxItems(filtered)
    } else {
      setBoxItems([])
    }
  }

  return (
    <Box className="bulk-edit">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Select Items
          </Typography>
        </Box>
      </Box>

      {isCreatingShipment || isCreatingAnotherShipment ? (
        <LinearProgress color="secondary" />
      ) : (
        <Stack height="4px"></Stack>
      )}

      <Box
        padding={1}
        borderBottom="1px solid"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap={4}
        bgcolor="var(--lighter)"
      >
        <StyledSearchBar
          placeholder="Search"
          className="search-item"
          cancelOnEscape
          onCancelSearch={() => setSearchText('')}
          onRequestSearch={(s) => setSearchText(s)}
        />
      </Box>

      <Stack direction="col" gap={1} p={1}>
        <CopytStyledButton
          sx={{width: '100%'}}
          variant={tab === 'unlisted' ? 'contained' : 'outline'}
          color="primary"
          onClick={() => setTab('unlisted')}
        >
          Unlisted
        </CopytStyledButton>
        <CopytStyledButton
          sx={{width: '100%'}}
          variant={tab === 'listed' ? 'contained' : 'outline'}
          color="primary"
          onClick={() => setTab('listed')}
        >
          Listed
        </CopytStyledButton>
      </Stack>

      {tab === 'unlisted' ? (
        <Box className="modal-body" maxHeight="85vh" padding="0" mb={2} overflow="auto">
          <Stack minWidth="1000px">
            <Stack direction="column" bgcolor="var(--lighter)">
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-around"
                borderBottom="1px solid"
                p={1}
                alignItems="center"
              >
                <Checkbox
                  checked={!isEmpty(boxItems) && boxItems?.length === unlistedItems?.data?.length}
                  indeterminate={!isEmpty(boxItems) && boxItems?.length !== unlistedItems?.data?.length}
                  onChange={handleCheckboxOnChange}
                  color="secondary"
                  disabled={isFetchingUnlistedItems || isCreatingShipment || isCreatingAnotherShipment}
                />
                <Stack direction="row" width="100%" justifyContent="space-between">
                  <CopytLabel width="30%">Item Name</CopytLabel>
                  <CopytLabel width="25%">Sku/Style ID</CopytLabel>
                  <CopytLabel width="25%">Internal SKU</CopytLabel>
                  <CopytLabel width="25%">Location</CopytLabel>
                  <CopytLabel width="15%">Size</CopytLabel>
                  <CopytLabel width="15%">Price</CopytLabel>
                </Stack>
              </Stack>
              {isFetchingUnlistedItems && <LoadingBar type="linear" />}
            </Stack>
            <Stack minHeight="50vh" maxHeight="60vh">
              {!isEmpty(unlistedItems?.data) &&
                unlistedItems?.data.map((item) => {
                  return (
                    <CopytTooltip
                      key={item.id}
                      title={
                        productsInActiveTracking?.includes(item?.id)
                          ? 'Product is already being actively tracked in another shipment.'
                          : ''
                      }
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        borderBottom="1px solid var(--light)"
                        alignItems="center"
                        padding={1}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'var(--lighter)',
                          },
                        }}
                      >
                        <Checkbox
                          onChange={() => handleCheckboxChange(item)}
                          color="secondary"
                          checked={boxItems.some((s) => s?.id === item?.id)}
                          disabled={
                            isFetchingUnlistedItems ||
                            isCreatingShipment ||
                            isCreatingAnotherShipment ||
                            productsInActiveTracking?.includes(item?.id)
                          }
                        />
                        <Stack
                          direction="row"
                          width="100%"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <CopytLabel width="30%">{item?.title}</CopytLabel>
                          <CopytLabel width="25%">{item?.sku}</CopytLabel>
                          <CopytLabel width="25%">{item?.internalSku}</CopytLabel>
                          <CopytLabel width="25%">{item?.location}</CopytLabel>
                          <CopytLabel width="15%">{item?.size}</CopytLabel>
                          <CopytLabel width="15%">{item?.desiredReturn}</CopytLabel>
                        </Stack>
                      </Stack>
                    </CopytTooltip>
                  )
                })}
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Box className="modal-body" maxHeight="85vh" padding="0" mb={2} overflow="auto">
          <Stack minWidth="1000px">
            <Stack direction="column" bgcolor="var(--lighter)">
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-around"
                borderBottom="1px solid"
                p={1}
                alignItems="center"
              >
                <Checkbox
                  checked={!isEmpty(boxItems) && boxItems?.length === listedItems?.data?.length}
                  indeterminate={!isEmpty(boxItems) && boxItems?.length !== listedItems?.data?.length}
                  onChange={handleCheckboxOnChange}
                  color="secondary"
                  disabled={
                    isFetchingUnlistedItems ||
                    isFetchingListedItems ||
                    isCreatingShipment ||
                    isCreatingAnotherShipment
                  }
                />
                <Stack direction="row" width="100%" justifyContent="space-between">
                  <CopytLabel width="30%">Item Name</CopytLabel>
                  <CopytLabel width="25%">Sku/Style ID</CopytLabel>
                  <CopytLabel width="25%">Internal SKU</CopytLabel>
                  <CopytLabel width="25%">Location</CopytLabel>
                  <CopytLabel width="15%">Size</CopytLabel>
                  <CopytLabel width="15%">Price</CopytLabel>
                </Stack>
              </Stack>
              {isFetchingListedItems && <LoadingBar type="linear" />}
            </Stack>
            <Stack minHeight="50vh" maxHeight="60vh">
              {!isEmpty(listedItems?.data) &&
                listedItems?.data.map((item) => {
                  return (
                    <CopytTooltip
                      key={item.id}
                      title={
                        productsInActiveTracking?.includes(item?.id)
                          ? 'Product is already being actively tracked in another shipment.'
                          : ''
                      }
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        borderBottom="1px solid var(--light)"
                        alignItems="center"
                        padding={1}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'var(--lighter)',
                          },
                        }}
                      >
                        <Checkbox
                          onChange={() => handleCheckboxChange(item)}
                          color="secondary"
                          checked={boxItems.some((s) => s?.id === item?.id)}
                          disabled={
                            isFetchingUnlistedItems ||
                            isFetchingListedItems ||
                            isCreatingShipment ||
                            isCreatingAnotherShipment ||
                            productsInActiveTracking?.includes(item?.id)
                          }
                        />
                        <Stack
                          direction="row"
                          width="100%"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <CopytLabel width="30%">{item?.title}</CopytLabel>
                          <CopytLabel width="25%">{item?.sku}</CopytLabel>
                          <CopytLabel width="25%">{item?.internalSku}</CopytLabel>
                          <CopytLabel width="25%">{item?.location}</CopytLabel>
                          <CopytLabel width="15%">{item?.size}</CopytLabel>
                          <CopytLabel width="15%">{item?.desiredReturn}</CopytLabel>
                        </Stack>
                      </Stack>
                    </CopytTooltip>
                  )
                })}
            </Stack>
          </Stack>
        </Box>
      )}

      <Stack direction="row" justifyContent="flex-end" p={2} borderTop="1px solid #eee" spacing={2}>
        <CopytStyledButton
          variant="outline"
          color="primary"
          disabled={isCreatingShipment || isCreatingAnotherShipment}
          onClick={() => setModalType(ModalType.CREATE_LABEL)}
        >
          Back
        </CopytStyledButton>
        <CopytStyledButton
          disabled={isEmpty(boxItems) || isCreatingShipment || isCreatingAnotherShipment}
          variant="contained"
          color="primary"
          onClick={async () => {
            if (selectedShipmentEventId) {
              if (createAnotherShipmentId) {
                await onSaveChanges({
                  createAnotherShipment: {
                    productIds: boxItems.map((s) => s?.id),
                    method: 'DUPLICATE',
                    type: 'EVENT',
                    provider: 'SHIPPO',
                    count: duplicateCount,
                    event: {
                      id: selectedShipmentEventId,
                    },
                  },
                })
              } else {
                await onSaveChanges({
                  createShipment: {
                    productIds: boxItems.map((s) => s?.id),
                    method: 'DIRECT',
                    type: 'EVENT',
                    provider: 'SHIPPO',
                    event: {
                      id: selectedShipmentEventId,
                    },
                  },
                })
              }
            }
          }}
        >
          Continue
        </CopytStyledButton>
      </Stack>
    </Box>
  )
}

export default MdlListedUnlistedItems
