import React, {useState, useEffect} from 'react'
import {range, sortBy} from 'lodash'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextareaAutosize,
  Typography,
} from '@mui/material'

import {CopytStyledButton} from 'views_v2/lib/snippets'
import {useGlobalStore} from 'provider/global_store/hook'
import {useNotes} from 'service/hook'
import useApplication from 'service/hook/useApplication'
import {useConsignmentShops} from 'service/hook'

const MdlNotes = ({formValues, onCloseModal}) => {
  const {isEnterprise} = useGlobalStore()
  const {onUpdateNote} = useConsignmentShops({
    options: {refetchInterval: 5000},
    // queries,
  })
  const [note, setNote] = useState(formValues?.note)

  const handlePressSubmit = () => {
    onUpdateNote({type: 'application', id: formValues?.applicationId, value: {note}})
    onCloseModal()
  }

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Notes
          </Typography>
        </Box>
      </Box>

      <Box className="modal-body" padding={2}>
        <TextareaAutosize
          style={{
            width: '100%',
            borderRadius: 6,
            padding: 10,
            resize: 'none',
          }}
          disabled={isEnterprise}
          value={note}
          minRows={10}
          maxRows={10}
          onChange={(e) => setNote(e?.target?.value)}
        />
      </Box>
      {!isEnterprise && (
        <Box className="modal-footer" padding={0} mb={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            px={2}
            pt={1}
            borderTop="1px solid var(--lighter)"
            spacing={2}
            width="100%"
          >
            <CopytStyledButton
              variant="contained"
              color="primary"
              sx={{width: 140}}
              onClick={handlePressSubmit}
            >
              Submit
            </CopytStyledButton>
          </Stack>
        </Box>
      )}
    </Box>
  )
}

export default MdlNotes
