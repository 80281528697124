import React, {useState, useReducer, useEffect} from 'react'
import {
  Checkbox,
  Chip,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
  Box,
} from '@mui/material'
import {isEmpty, map} from 'lodash'
import {ContentCopy, MoreVert as MoreVertIcon} from '@mui/icons-material'
import {styled} from '@mui/material/styles'

import CopytModal from 'components/CopytModal'
import {ModalType} from 'enums'
import {useShipments} from 'service/hook'
import DateUtils from 'util/date_utils'
import {_getAddress, _getStatus, _getValue, capitalizeText} from 'util/string_utils'
import {getCurrency} from 'util/model/setting'
import {CopytButton} from 'views_v2/lib/snippets'
import MdlDeleteLabel from './modal-contents/MdlDeleteLabel'
import {MdlRefund} from './modal-contents'
import {useToastMessage} from 'components'
import {CopytTooltip} from 'views_v2/lib/snippets'

export const Item = styled(MenuItem)(() => ({
  width: '100%',
  justifyContent: 'flex-start !important',
}))

export const ItemButton = styled(CopytButton)((p) => ({
  ...p,
  lineHeight: '12px',
  width: '150px',
  height: '35px',
  borderRadius: '6px !important',
}))

const ShippingTableRow = (p) => {
  const {
    row,
    user,
    transactions,
    onSelectShipment,
    setModalType,
    getShipmentById,
    deleteShipment,
    isDeletingShipment,
    setCreateAnotherShipmentId,
  } = p
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const currency = getCurrency(row?.paymentMetadata?.currency)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [isOpenRefund, setIsOpenRefund] = useState(false)
  const [isOpenDeleteLabel, setIsOpenDeleteLabel] = useState(false)
  const {refundShipment, isErrorRefundShipment, isRefundingShipment} = useShipments()
  const {showToast} = useToastMessage()

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const onSaveChanges = async () => {
    await refundShipment({transaction: row?.shippoTransactionId})
    setIsOpenRefund(false)
  }

  const handleDeleteLabel = async () => {
    await deleteShipment(row.id)
    setIsOpenDeleteLabel(false)
  }

  const handleOpenShipmentMdl = async () => {
    if (['QUEUED', 'PENDING', 'SUCCESS'].includes(row?.refund) || row.owner.id !== user.id) return

    const {id, status, isPaid, shippoShipmentId} = row

    const isDraft = status === 'DRAFT'

    if (isDraft && !shippoShipmentId) {
      await getShipmentById(id)
      setModalType(ModalType.PACKAGE_DETAILS)
      return
    }

    if (isDraft && shippoShipmentId) {
      await getShipmentById(id)
      setModalType(ModalType.PURCHASE_LABEL)
      return
    }

    if (isPaid) {
      await getShipmentById(id)
      setModalType(ModalType.SHIPMENT_DETAILS)
      return
    }
  }

  const getStatus = () => {
    let status = row?.refund || row?.status
    let statusLabel = status
    let tooltipMessage = ''

    let statusColor = 'primary'

    if (status === 'ERROR') {
      status = row?.status
    }

    switch (status) {
      case 'DRAFT':
        statusColor = 'warning'
        break
      case 'DELIVERED':
        // ? Refunded both shippo and stripe
        statusColor = 'success'
        break
      case 'QUEUED':
      case 'PENDING':
        // ? Waiting for shippo refund
        statusColor = 'warning'
        statusLabel = 'pending_refund'
        tooltipMessage = 'Refunds may take up to 14 days to credit into your account.'
        break
      case 'FAILURE':
        // ? Failed to ship
        statusColor = 'error'
        break
      case 'SUCCESS':
        // ? Refunded both shippo and stripe
        statusColor = 'error'
        statusLabel = 'refunded'
        break
      case 'FAILED':
        // ? Refunded only on shippo
        statusColor = 'error'
        statusLabel = 'refund_failed'
        break
    }

    return (
      <CopytTooltip title={tooltipMessage}>
        <Chip
          label={capitalizeText(statusLabel)}
          color={statusColor}
          size="small"
          sx={{
            width: '150px',
          }}
        />
      </CopytTooltip>
    )
  }

  useEffect(() => {
    if (isErrorRefundShipment && isOpenRefund) {
      setIsOpenRefund(false)
    }
  }, [isErrorRefundShipment])

  return (
    <TableRow
      sx={{
        border: '1px solid gray',
        cursor:
          !['QUEUED', 'PENDING', 'SUCCESS'].includes(row?.refund) &&
          row.owner.id === user.id &&
          'pointer',
        '&:hover': {
          backgroundColor:
            !['QUEUED', 'PENDING', 'SUCCESS'].includes(row?.refund) &&
            row.owner.id === user.id &&
            '#f0f0f0',
        },
      }}
      onClick={handleOpenShipmentMdl}
    >
      <TableCell>
        <Checkbox
          size="small"
          onClick={(e) => e.stopPropagation()}
          disabled={!row?.shippoTransactionId || ['QUEUED', 'PENDING', 'SUCCESS'].includes(row?.refund)}
          checked={transactions?.some((s) => s === row?.shippoTransactionId)}
          onChange={(e) => onSelectShipment(row?.shippoTransactionId)}
          sx={{
            color: 'white',
            '&.Mui-checked': {
              color: 'var(--darker)',
            },
          }}
        />
      </TableCell>
      <TableCell>
        <Typography color={row?.purchasedAt ? 'black' : 'gray'} fontSize={14}>
          {row?.purchasedAt ? DateUtils.toShortDate(row?.purchasedAt) : '-- / -- / --'}
        </Typography>
      </TableCell>
      <TableCell>{getStatus()}</TableCell>
      <TableCell>
        <Stack spacing={1}>
          <Stack>
            <Typography color="black" fontWeight={600} fontSize="14px">
              {row?.recipientAddress?.name}
            </Typography>
            <Typography fontWeight={500} fontSize="12px">
              {row?.recipientAddress?.email}
            </Typography>
          </Stack>
          <Typography color="var(--gray)" fontWeight={500} fontSize="12px">
            {_getAddress(row?.recipientAddress)}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {row?.type === 'CONSIGNMENT' ? (
          <Stack spacing={1}>
            <Stack>
              <Typography color="black" fontWeight={600} fontSize="14px">
                {capitalizeText(row?.type)}
              </Typography>
              {row?.recipient && (
                <Typography fontWeight={500} fontSize="12px">
                  {row.owner.id === user.id ? row?.recipient?.email : row?.sender?.email}
                </Typography>
              )}
            </Stack>
            {row?.recipient && (
              <Typography color="var(--gray)" fontWeight={500} fontSize="12px">
                {row.owner.id === user.id ? 'CONSIGNEE' : 'CONSIGNOR'}
              </Typography>
            )}
          </Stack>
        ) : row?.type === 'EVENT' ? (
          <Stack spacing={1}>
            <Typography color="black" fontWeight={600} fontSize="14px">
              {capitalizeText(row?.type)}
            </Typography>
            {row?.event && (
              <Typography fontWeight={500} fontSize="12px">
                {row.event.name}
              </Typography>
            )}
          </Stack>
        ) : (
          <Typography color="black" fontWeight={600} fontSize="14px">
            {capitalizeText(row?.type)}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Stack>
          <Typography color="black" fontWeight={600} fontSize="14px">
            {`${parseFloat(row?.parcel?.weight) || '--'} ${row?.parcel?.mass_unit || '--'}`}
          </Typography>
          <Typography color="var(--gray)" fontWeight={500} fontSize="13px">
            {parseFloat(row.parcel.length) || '--'} x {parseFloat(row?.parcel?.width) || '--'} x{' '}
            {parseFloat(row?.parcel?.height) || '--'} {row?.parcel?.distance_unit}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {!isEmpty(row?.rate) && row?.refund !== 'SUCCESS' && (
          <Stack flex direction="row" gap={2} alignItems="center" justifyContent="space-between">
            <a role="button" href={row?.url} target="_blank" onClick={(e) => e.stopPropagation()}>
              <Stack spacing={1}>
                <Stack flex direction="row" gap={1} alignItems="center">
                  <Box component="img" width={24} src={row?.rate?.provider_image_200} />
                  <Typography fontSize={14} fontWeight={600} color="black">
                    {row?.rate?.servicelevel?.name}
                  </Typography>
                </Stack>
                <Typography fontSize={12}>{row?.number}</Typography>
              </Stack>
            </a>
            <ContentCopy
              fontSize="small"
              onClick={() => {
                navigator.clipboard.writeText(row?.number)
                showToast({variant: 'success', title: 'Tracking number copied', body: ''})
              }}
            />
          </Stack>
        )}
      </TableCell>
      <TableCell>
        {row.owner.id === user.id && row?.isPaid ? currency.format(_getValue(row?.price)) : '--.--'}
      </TableCell>
      <TableCell
        sx={{
          minWidth: 300,
        }}
      >
        {!isEmpty(row?.products) && (
          <Box>
            <Typography fontSize={12} color="black" fontWeight={600}>
              {row?.products?.length} {row?.products?.length > 1 ? 'Items' : 'Item'}
            </Typography>
            <Typography fontSize={12} color="black">
              {map(row?.products?.slice(0, 5), 'product.sku').join(', ')}
              {row?.products?.length > 5 ? ',...' : ''}
            </Typography>
          </Box>
        )}
      </TableCell>
      <TableCell sx={{padding: 0}}>
        <IconButton
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{float: 'right'}}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            style: {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              gap: 4,
            },
          }}
        >
          <Item
            onClick={(event) => {
              event.stopPropagation()
              navigator.clipboard.writeText(row?.id)
              showToast({variant: 'success', title: 'Shipping ID copied', body: ''})
            }}
          >
            <ItemButton color="primary">Copy Shipping ID</ItemButton>
          </Item>

          <Item
            onClick={(event) => {
              event.stopPropagation()
              setAnchorEl(null)
              setCreateAnotherShipmentId(row?.id)
              setModalType(ModalType.CREATE_LABEL)
            }}
            disabled={isEmpty(row?.parcel) || row.owner.id !== user.id}
          >
            <ItemButton color="primary">Create Another</ItemButton>
          </Item>

          <Item
            onClick={(event) => {
              event.stopPropagation()
              window.open(row?.label, '_blank')
            }}
            disabled={!row?.label || ['QUEUED', 'PENDING', 'SUCCESS'].includes(row?.refund)}
          >
            <ItemButton color="primary">Download Label</ItemButton>
          </Item>

          <Item
            disabled={
              !row?.isPaid ||
              row?.refund ||
              ['DELIVERED', 'RETURNED', 'FAILURE'].includes(row?.status) ||
              row.owner.id !== user.id
            }
            onClick={(event) => {
              event.stopPropagation()
              setIsOpenRefund(true)
              setAnchorEl(null)
            }}
          >
            <ItemButton color="primary">Refund Label</ItemButton>
          </Item>
          <Item
            onClick={(event) => {
              event.stopPropagation()
              setIsOpenDeleteLabel(true)
              setAnchorEl(null)
            }}
            disabled={row?.status !== 'DRAFT' || row.owner.id !== user.id}
            style={{
              marginTop: 20,
              paddingTop: 15,
              borderTop: '1px solid var(--lighter)',
            }}
          >
            <ItemButton
              variant="contained"
              color="error"
              style={{
                backgroundColor: 'error',
              }}
            >
              Delete
            </ItemButton>
          </Item>
        </Menu>
      </TableCell>
      <CopytModal
        className="no-padding"
        id="create-box-preset"
        show={isOpenRefund}
        size="md"
        toggle={() => null}
        closeButton={{
          hidden: true,
        }}
      >
        <MdlRefund
          onCloseModal={() => setIsOpenRefund(false)}
          onSaveChanges={onSaveChanges}
          isLoading={isRefundingShipment}
        />
      </CopytModal>

      <CopytModal
        className="no-padding"
        id="create-box-preset"
        show={isOpenDeleteLabel}
        size="xs"
        toggle={() => null}
        closeButton={{
          hidden: true,
        }}
      >
        <MdlDeleteLabel
          onCloseModal={() => setIsOpenDeleteLabel(false)}
          onSaveChanges={handleDeleteLabel}
          isLoading={isDeletingShipment}
        />
      </CopytModal>
    </TableRow>
  )
}

export default ShippingTableRow
