import React from 'react'
import {Box, Stack, Typography} from '@mui/material'
import Barcode from 'react-barcode'

import {ConsignorCode} from 'views_v2/modules/Inventory/components/modal-contents/MdlBarcodeTemplate'
import {GridItem} from 'views_v2/modules/Inventory/components/modal-contents/MdlBarcodeTemplate'
import {TextTemplate} from 'views_v2/modules/Inventory/components/modal-contents/MdlBarcodeTemplate'

const Template11 = ({topStrings, showBarcodeTemplate}) => {
  return (
    <Box px={2}>
      <Box width="100%" minHeight="150px" bgcolor="var(--light)" borderRadius={2} p={1}>
        <Stack
          flex={1}
          minHeight="130px"
          height="170px"
          maxHeight="170px"
          bgcolor="white"
          borderRadius={2}
          gap={2}
        >
          <Box>
            <Typography fontWeight={600} fontSize={16} textAlign="center" paddingTop={1}>
              Item Name
            </Typography>
          </Box>

          <Stack px={2}>
            <Stack direction="row" gap={1} justifyContent="space-between">
              {topStrings.map((str, index) => (
                <GridItem item key={index} px={1} visibility={str?.isHidden ? 'hidden' : ''}>
                  <TextTemplate>{str.label}</TextTemplate>
                </GridItem>
              ))}
            </Stack>
            <Barcode value="Internal Sku" height={45} width={2.4} fontSize={12} />

            {showBarcodeTemplate?.consignorCode && <ConsignorCode />}
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

export default Template11
